import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { MenuItem, Stack, useTheme } from "@mui/material";
import axios from "axios";
import { ToastContext } from "../../shared/ToastContext";
import { BackToButton } from "../../shared/BackToButton";
import LiTextButton from "../../shared/LiTextButton";
import { ANI_TYPES } from "../../constants";

// TODO Allow to add retroactive date. So add an optional date field.

export default function Add(props) {
  const { navigate } = props;

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { setToastText } = React.useContext(ToastContext);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const submit = async (values) => {
    setSubmitDisabled(true);

    const { type, title, author = null } = values;
    console.log(title, type, author);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ani`, {
        title,
        type,
        author,
      });
      navigate("list");
      setToastText("Added");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BackToButton
        navigate={navigate}
        text="Back to List"
        destination="list"
      />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(submit)}
        sx={{ mt: 4, width: theme.my.formWidth }}
      >
        <Stack spacing={2}>
          <TextField
            select
            labelId="type"
            variant="standard"
            id="type"
            defaultValue="book"
            label="Type"
            fullWidth
            {...register("type", {})}
            error={!!errors.type}
            helperText={errors.type?.message}
          >
            {ANI_TYPES.map((t) => (
              <MenuItem value={t}>
                {t.charAt(0).toUpperCase() + t.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name="title"
            variant="standard"
            required
            fullWidth
            id="title"
            label="Title"
            autoFocus
            {...register("title", { required: "Title is required" })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />

          <TextField
            fullWidth
            variant="standard"
            id="author"
            label="Author"
            name="author"
            {...register("author", {})}
            error={!!errors.author}
            helperText={errors.author?.message}
          />
        </Stack>
        <Box sx={{ mt: 4 }}>
          <LiTextButton text="Add" disabled={submitDisabled} />
        </Box>
      </Box>
    </Box>
  );
}
