import {
  Box,
  Button,
  Card,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { DateTime } from "luxon";
import { useContext } from "react";
import { useState } from "react";
import { ToastContext } from "./ToastContext";
import { ANI_SHORTCUTS } from "../constants";

const MyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.dark,
    // color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[0],
    fontFamily: "AlpinaStandard",
    fontWeight: 400,
    fontSize: 12,
  },
}));

export default function AnI(props) {
  const {
    anI,
    navigate,
    setCurrentAnI,
    shortFormat = false,
    noActions = false,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardOpen, setCardOpen] = useState(false);
  const theme = useTheme();
  const { setToastText } = useContext(ToastContext);

  const handleMenuClose = async (option) => {
    console.log("handleClose", option);
    setMenuOpen(false);
    if (option === "completed") {
      setCurrentAnI(anI);
      navigate("critique");
    } else if (option === "delete") {
      setCurrentAnI(anI);
      navigate("delete");
    } else if (option === "edit") {
      setCurrentAnI(anI);
      navigate("edit");
    } else if (option === "copy") {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ani`, {
          title: anI.title,
          type: anI.type,
          author: anI.author,
        });
        setToastText("Copied");
      } catch (error) {
        console.log(error);
        if (error.response.data.message) {
          setToastText(error.response.data.message);
        } else {
          setToastText("Something went wrong. Please try again ...");
        }
      }
    }
  };
  const handleMenuClick = (event) => {
    console.log("handleClick");
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCardClick = () => {
    console.log("card click");
    cardOpen ? setCardOpen(false) : setCardOpen(true);
  };
  return (
    <Card
      onClick={shortFormat ? null : handleCardClick}
      sx={{
        border: 0,
        marginTop: "8px",
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.2)",
        backgroundColor:
          anI.status === "completed" ? "secondary.light" : "secondary.main",
        width: {
          xs: shortFormat ? theme.my.formWidth : theme.my.shortWidth, // For screens smaller than sm (600px by default) 450px in my case
          sm: shortFormat ? theme.my.formWidth : theme.my.width, // For screens larger than or equal to sm
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: shortFormat ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!shortFormat && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MyTooltip title={anI.type} enterDelay={1000}>
                <Typography
                  variant="t1"
                  color="t.dark"
                  align="center"
                  sx={{ width: "50px" }}
                >
                  {ANI_SHORTCUTS[anI.type]}
                </Typography>
              </MyTooltip>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <Typography align="center" variant="s3" color="t.dark">
              {anI.title}
            </Typography>

            <Typography
              align="center"
              variant="s3"
              color="t.lightDark"
              sx={{ fontStyle: "Italic" }}
            >
              {anI?.author ? anI.author : "no author"}
            </Typography>
          </Box>

          {!shortFormat && (
            <Box
              sx={{
                width: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {anI?.completedAt && (
                <Typography color="t.dark" variant="s1">
                  {/* {DateTime.fromISO(anI.completedAt).toFormat("LLL yy")} */}
                  {DateTime.fromISO(anI.completedAt).toFormat("MMyy")}
                </Typography>
              )}
              <Button
                variant="text"
                onClick={handleMenuClick}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent !important",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "transparent !important",
                  },
                  "&:focus": {
                    boxShadow: "none",
                  },
                }}
              >
                <Typography>...</Typography>
              </Button>
            </Box>
          )}

          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            elevation={0}
            // sx={{
            //   boxShadow:
            //     "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            //   "& .MuiMenu-list": {
            //     padding: "4px 0",
            //   },
            // }}
          >
            {noActions ? (
              <MenuItem onClick={() => handleMenuClose("copy")}>
                <Typography variant="s3" sx={{ fontStyle: "Italic" }}>
                  Copy to my list
                </Typography>
              </MenuItem>
            ) : (
              [
                anI.status !== "completed" && (
                  <MenuItem
                    key="completed"
                    onClick={() => handleMenuClose("completed")}
                  >
                    <Typography variant="s3" sx={{ fontStyle: "Italic" }}>
                      Completed
                    </Typography>
                  </MenuItem>
                ),
                anI.status === "completed" && (
                  <MenuItem key="edit" onClick={() => handleMenuClose("edit")}>
                    <Typography variant="s3" sx={{ fontStyle: "Italic" }}>
                      Edit
                    </Typography>
                  </MenuItem>
                ),
                <MenuItem
                  key="delete"
                  onClick={() => handleMenuClose("delete")}
                >
                  <Typography variant="s3" sx={{ fontStyle: "Italic" }}>
                    Delete
                  </Typography>
                </MenuItem>,
              ].filter(Boolean) // This filter removes any falsy values from the array
            )}
          </Menu>
        </Box>
        {cardOpen && (
          <Typography variant="t3" sx={{ paddingLeft: 2, paddingRight: 2 }}>
            {anI?.critique}
          </Typography>
        )}
      </Box>
    </Card>
  );
}
