import { Box, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { ToastContext } from "./ToastContext";

export default function Scene({ children }) {
  const [toastText, setToastText] = useState(null);

  const handleClose = () => {
    setToastText(null);
  };

  return (
    <Box>
      <ToastContext.Provider value={{ setToastText }}>
        {children}
      </ToastContext.Provider>
      <Snackbar
        open={toastText !== null}
        autoHideDuration={4000}
        onClose={handleClose}
        message={toastText}
      />
    </Box>
  );
}
