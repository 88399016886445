import { useEffect, useState } from "react";
import axios from "axios";
import AnIList from "../../shared/AnIList";

export default function List(props) {
  const { navigate, setCurrentAnI } = props;

  const [completed, setCompleted] = useState([]);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ani`);
        console.log(res);

        setCompleted(res.data.completed);
        setOpen(res.data.open);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <AnIList
        open={open}
        completed={completed}
        navigate={navigate}
        setCurrentAnI={setCurrentAnI}
      />
    </>
  );
}
