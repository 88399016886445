import * as React from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Alert, Stack, useTheme } from "@mui/material";
// import { COUNTRIES, GENDER } from "../constants";
import LiButton from "../shared/LiButton";

// TODO Later on convert to coordinates to find people around you and have a flag if it was successful or not
// TODO display terms and conditions
// TODO make sure user is in the Auth Context
// TODO allow to update details (name) or just city and country. Not now. Just adding a pic.

export default function Register() {
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const password = watch("password"); // Watch the value of the 'password' field

  const [backendMessage, setBackendMessage] = React.useState(null);
  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const submit = async (values) => {
    setSubmitDisabled(true);
    // const { email, password, country, gender, name, age } = values;
    const { email, password, name } = values;

    console.log(email, password, name);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/register`,
        {
          email,
          password,
          // country,
          // gender,
          name,
          // age,
        }
      );
      console.log(res);
      setBackendMessage("Success! Please verify your email before signing in");
    } catch (error) {
      console.log(error);
      setBackendMessage(error?.response?.data);
      setSubmitDisabled(false);
    }
  };

  return (
    <Box
      mt={4}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1">Les intellectuels</Typography>
      {/* {backendMessage && <ErrorMessage message={backendMessage} /> } */}

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(submit)}
        sx={{ mt: 3, width: theme.my.formWidth }}
      >
        <Stack spacing={2}>
          {backendMessage && (
            <Alert icon={false} severity="error">
              {backendMessage}
            </Alert>
          )}
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            variant="standard"
            {...register("email", {
              required: "Email address is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            variant="standard"
            {...register("name", {
              required: "Name is required",
              pattern: {
                value: /^(.{6,30})$/,
                message: "Name must be between 6 and 30 characters",
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          {/* <TextField
            required
            fullWidth
            id="age"
            label="Age"
            name="age"
            autoComplete="age"
            variant="standard"
            {...register("age", {
              required: "Age is required",
              pattern: {
                value: /^(1[5-9]|[2-9][0-9]|120)$/i,
                message: "Age must be between 15 and 120",
              },
            })}
            error={!!errors.age}
            helperText={errors.age?.message}
          /> */}
          {/* <TextField
            id="gender"
            select
            defaultValue={
              GENDER[Math.floor(Math.random() * (GENDER.length - 1 - 0 + 1))]
            }
            label="Gender"
            name="gender"
            {...register("gender", {})}
            fullWidth
            required
            variant="standard"
          >
            {GENDER.map((e) => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </TextField> */}
          {/* <TextField
            select
            id="country"
            name="country"
            {...register("country", {})}
            defaultValue={
              COUNTRIES[
                Math.floor(Math.random() * (COUNTRIES.length - 1 - 0 + 1))
              ]
            }
            label="Country"
            fullWidth
            required
            variant="standard"
          >
            {COUNTRIES.map((e) => (
              <MenuItem value={e}>{e}</MenuItem>
            ))}
          </TextField> */}
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "Password is required",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/,
                message:
                  "Your password must be between 8-16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters",
              },
            }}
            render={({ field }) => (
              <TextField
                required
                variant="standard"
                label="Password"
                fullWidth
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: "Retyping Password is required",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/,
                message:
                  "Your password must be between 8-16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters",
              },
              validate: (value) =>
                value === password || "Passwords do not match",
            }}
            render={({ field }) => (
              <TextField
                required
                label="Confirm Password"
                variant="standard"
                fullWidth
                type="password"
                id="confirmPassword"
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                {...field}
              />
            )}
          />
          <Box>
            <Controller
              name="termsAndConditions"
              control={control}
              defaultValue={false}
              rules={{ required: "You must accept the terms and conditions" }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox size="small" {...field} />}
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      I agree to the{" "}
                      <Link href="https://www.example.com">
                        terms & conditions
                      </Link>
                    </Typography>
                  }
                  style={{
                    fontSize: "10px",
                    "& .MuiTypography-body1": { fontSize: "10px" },
                  }}
                />
              )}
            />
            {errors.termsAndConditions && (
              <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                {errors.termsAndConditions.message}
              </Typography>
            )}
          </Box>
        </Stack>

        <LiButton text="Sign Up" disabled={submitDisabled} />
      </Box>
      <Link href="/" variant="t2" sx={{ mt: "2px" }}>
        Already have an account? Sign in
      </Link>
    </Box>
  );
}
