import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Link from "@mui/material/Link";

export default function ResetPassword() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const { token } = useParams();

  const [updated, setUpdated] = React.useState(false);

  const password = watch("password"); // Watch the value of the 'password' field

  const submit = async (values) => {
    const { password } = values;
    console.log(password);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/password`, {
        token,
        password,
      });
      setUpdated(true);
    } catch (error) {
      console.log(error.toString());
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {updated ? (
          <Box>
            <Typography variant="body1">
              Password was updated successfully
            </Typography>
            <Link href="/" variant="body1">
              Go to the sign in page
            </Link>
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(submit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/,
                      message:
                        "Your password must be between 8-16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      required
                      label="Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Retyping Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/,
                      message:
                        "Your password must be between 8-16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters",
                    },
                    validate: (value) =>
                      value === password || "Passwords do not match",
                  }}
                  render={({ field }) => (
                    <TextField
                      required
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      type="password"
                      id="confirmPassword"
                      autoComplete="new-password"
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}
