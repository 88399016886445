import { Box } from "@mui/material";
import Bar from "../../shared/Bar";
import { useState } from "react";
import Add from "./Add";
import Critique from "./Critique";
import Delete from "./Delete";
import Scene from "../../shared/Scene";
import Edit from "./Edit";
import Navigation from "../../shared/Navigation";
import List from "./List";

export default function Dashboard() {
  // possible pages are: list, add, edit, critique
  const [page, setPage] = useState("list");
  const [currentAnI, setCurrentAnI] = useState(null);

  console.log("In Dashboard");
  return (
    <Scene>
      <Bar />
      <Box
        component="span"
        sx={{
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
          marginBottom: 4,
        }}
      >
        {page === "list" && (
          <List
            navigate={setPage}
            setCurrentAnI={setCurrentAnI}
            // currentAnI={currentAnI}
          />
        )}
        {page === "add" && <Add navigate={setPage} />}
        {page === "critique" && (
          <Critique navigate={setPage} anI={currentAnI} />
        )}
        {page === "edit" && <Edit navigate={setPage} anI={currentAnI} />}
        {page === "delete" && <Delete navigate={setPage} anI={currentAnI} />}
      </Box>
      <Navigation />
    </Scene>
  );
}
