import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./scenes/Dashboard/Dashboard";
import SignIn from "./scenes/SignIn";
import Register from "./scenes/Register";
import { ProtectedRoute } from "./ProtectedRoute";
import AuthContextProvider from "./auth/AuthContextProvider";
import VerifyEmail from "./scenes/VerifyEmail";
import ForgotPassword from "./scenes/ForgotPassword";
import ResetPassword from "./scenes/ResetPassword";
import { theme } from "./Theme";
import { Helmet } from "react-helmet";
import Search from "./scenes/Search/Search";
import Following from "./scenes/Following/Following";

theme.spacing(2);

const router = createBrowserRouter([
  {
    // path: "signin/",
    path: "/",
    element: <SignIn />,
  },
  {
    path: "register/",
    element: <Register />,
  },
  {
    path: "dashboard/",
    element: <ProtectedRoute component={Dashboard} />,
  },
  {
    path: "search/",
    element: <ProtectedRoute component={Search} />,
  },
  {
    path: "following/",
    element: <ProtectedRoute component={Following} />,
  },
  {
    path: "verify/:token",
    element: <VerifyEmail />,
  },
  {
    path: "forgot/",
    element: <ForgotPassword />,
  },
  {
    path: "password/:token",
    element: <ResetPassword />,
  },
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title style={{ fontFamily: "AlpinaStandard", fontSize: "16px" }}>
            i
          </title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </ThemeProvider>
  );
}
