import { Box, Link, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function VerifyEmail() {
  const { token } = useParams();
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    async function verify() {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/verify`,
          { token },
          { withCredentials: true }
        );
        console.log(res?.status);
        if (res.status === 200) setVerified(true);
      } catch (error) {
        console.log(error);
        setVerified(false);
      }
    }
    verify();
  }, [token]);

  return verified ? (
    <Box sx={{ mt: 5, marginLeft: 5 }}>
      <Typography variant="body1">
        Your email address is verified. Thank you!{" "}
      </Typography>
      <Link href="/" variant="body1">
        You may now sign in{" "}
      </Link>
    </Box>
  ) : (
    <Typography>Your email address could not be verified.</Typography>
  );
}
