import { Button, Typography, styled } from "@mui/material";

const PREFIX = "LiTextButton";

const classes = {
  root: `${PREFIX}-root`,
};
const MyButton = styled(Button)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.secondary.dark,

    "&:hover": {
      backgroundColor: theme.palette.background.default,
      borderWidth: "1px", // Set the border thickness and color on hover
      boxShadow: "none",
      borderColor: "none",
    },
    // "&:disabled": {
    //   backgroundColor: theme.palette.error.light,
    // },
    "&:active": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default function LiTextButton({
  text,
  onClick = "submit",
  textVariant = "t5",
  textStyle = {},
}) {
  return (
    <MyButton
      fullWidth
      disableRipple
      className={classes.root}
      variant="text"
      size="medium"
      onClick={onClick === "submit" ? undefined : onClick}
      type={onClick === "submit" ? "submit" : undefined}
    >
      <Typography variant={textVariant} sx={{ ...textStyle }}>
        {text}
      </Typography>
    </MyButton>
  );
}
