import * as React from "react";
import TextField from "@mui/material/TextField";
import { Typography, Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, Stack, useTheme } from "@mui/material";
import axios from "axios";
import { ToastContext } from "../../shared/ToastContext";
import {
  ANI_TYPES,
  MAX_CRITIQUE_CHARACTERS,
  MIN_CRITIQUE_CHARACTERS,
} from "../../constants";
import LiTextButton from "../../shared/LiTextButton";
import { BackToButton } from "../../shared/BackToButton";

export default function Edit(props) {
  const { navigate, anI } = props;

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const { setToastText } = React.useContext(ToastContext);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const critiqueValue = watch("critique", anI.critique);

  const submit = async (values) => {
    setSubmitDisabled(true);

    const { type, title, critique, author = null } = values;
    console.log(title, type, author, critique);
    console.log(anI._id);

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/ani`, {
        id: anI._id,
        fieldsToUpdate: {
          title,
          type,
          author,
          critique,
        },
      });
      setToastText("Updated");
      navigate("list");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BackToButton
        navigate={navigate}
        text="Back to List"
        destination="list"
      />
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(submit)}
        sx={{ mt: 4, width: theme.my.formWidth }}
      >
        <Stack spacing={2}>
          <TextField
            select
            labelId="type"
            variant="standard"
            id="type"
            defaultValue="book"
            label="Type"
            fullWidth
            {...register("type", {})}
            error={!!errors.type}
            helperText={errors.type?.message}
          >
            {ANI_TYPES.map((t) => (
              <MenuItem value={t}>
                {t.charAt(0).toUpperCase() + t.slice(1)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name="title"
            variant="standard"
            required
            fullWidth
            id="title"
            label="Title"
            defaultValue={anI.title}
            autoFocus
            {...register("title", { required: "Title is required" })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />

          <TextField
            fullWidth
            variant="standard"
            id="author"
            label="Author"
            name="author"
            defaultValue={anI.author}
            {...register("author", {})}
            error={!!errors.author}
            helperText={errors.author?.message}
          />
          <Box>
            <Controller
              name="critique"
              control={control}
              rules={{
                required: true,
                minLength: MIN_CRITIQUE_CHARACTERS,
                maxLength: MAX_CRITIQUE_CHARACTERS,
              }}
              defaultValue={anI.critique}
              render={({ field }) => (
                <TextField
                  name="critique"
                  required
                  fullWidth
                  multiline
                  rows={6}
                  id="critique"
                  label="Critique"
                  variant="standard"
                  error={!!errors.critique}
                  helperText={
                    errors.critique &&
                    `Critique length should be between ${MIN_CRITIQUE_CHARACTERS} and ${MAX_CRITIQUE_CHARACTERS} characters`
                  }
                  inputProps={{ maxLength: MAX_CRITIQUE_CHARACTERS }} // Set the maximum length
                  {...field}
                />
              )}
            />

            <Typography variant="s1" color="t.lightDark">
              {critiqueValue.length}/{MAX_CRITIQUE_CHARACTERS}
            </Typography>
          </Box>
        </Stack>
        <Box sx={{ mt: 4 }}>
          <LiTextButton text="Update" disabled={submitDisabled} />
        </Box>
      </Box>
    </Box>
  );
}
