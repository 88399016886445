import { Box } from "@mui/material";
import axios from "axios";
import { ToastContext } from "../../shared/ToastContext";
import React, { useContext } from "react";
import AnI from "../../shared/AnI";
import LiTextButton from "../../shared/LiTextButton";
import { BackToButton } from "../../shared/BackToButton";

export default function Delete(props) {
  const { navigate, anI } = props;
  const { setToastText } = useContext(ToastContext);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const handleDelete = async () => {
    setSubmitDisabled(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/ani/${anI._id}`);
      navigate("list");
      setToastText("Deleted");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BackToButton
        navigate={navigate}
        text="Back to List"
        destination="list"
      />
      <Box sx={{ mt: 2, mb: 4 }}>
        <AnI anI={anI} shortFormat={true} />
      </Box>
      <LiTextButton
        onClick={handleDelete}
        text="Delete"
        disabled={submitDisabled}
      />
    </Box>
  );
}
