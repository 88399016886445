import * as React from "react";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../auth/AuthContext";
import { Alert, Stack, useTheme } from "@mui/material";
import LiButton from "../shared/LiButton";

export default function SignIn() {
  const theme = useTheme();
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = useForm();

  const navigate = useNavigate();

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const { authenticated, updateAuthenticated, setUser } =
    React.useContext(AuthContext);

  const [backendError, setBackendError] = React.useState(null);

  React.useEffect(() => {
    console.log("formState", isDirty, isValid, errors);
    setBackendError(null);
  }, [isDirty, isValid, errors]);

  const submit = async (values) => {
    setSubmitDisabled(true);
    // console.log(JSON.stringify(values));
    setBackendError(null);
    const { email, password } = values;
    try {
      console.log(`${process.env.REACT_APP_BACKEND_URL}/auth/login`);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email,
          password,
        }
      );
      // console.log("res", res);
      // console.log("about to redirect");

      //Update the auth context bases on res.status
      setUser(res.data.user);
      updateAuthenticated(true);

      navigate("/dashboard");
    } catch (error) {
      console.log("sign in error", error);
      setBackendError(error?.response?.data);
      setSubmitDisabled(false);
    }
  };

  console.log(`signin, authenticated is ${authenticated}`);

  return authenticated ? (
    <Navigate to="/dashboard" replace={false} />
  ) : (
    <Box
      mt={8}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1">Les intellectuels</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(submit)}
        noValidate
        sx={{ mt: 3.5, width: theme.my.formWidth }}
      >
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          {backendError && (
            <Alert
              icon={false}
              severity="error"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 600,
              }}
            >
              {backendError}
            </Alert>
          )}
          <TextField
            variant="standard"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register("email", {
              required: "Email address is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Enter a valid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            variant="standard"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password", {
              required: "Password is required",
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$/,
                message:
                  "Your password must be between 8-16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters",
              },
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Stack>

        <LiButton text="Sign In" disabled={submitDisabled} />

        <Stack spacing={1} sx={{ alignItems: "center" }}>
          <Link href="/forgot" variant="t2">
            Forgot password?
          </Link>
          <Link href="/register" variant="t2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}
