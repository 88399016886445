import {
  Box,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { AuthContext } from "../auth/AuthContext";
import axios from "axios";
import { useState } from "react";
import LiTextButton from "./LiTextButton";

export default function Bar() {
  const { updateAuthenticated, user } = React.useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("isSmallScreen", isSmallScreen);

  const handleMenuClose = async (option) => {
    console.log("handleClose", option);
    setOpen(false);
    if (option === "signout") {
      console.log("signout");
      // const res =
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/signout`,
        {},
        { withCredentials: true }
      );
      await updateAuthenticated(false);
      // console.log(res);
    }
  };
  const handleMenuClick = (event) => {
    console.log("handleClick", event.currentTarget);
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      position="static"
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "50px",
        alignItems: "center", //vertical
        justifyContent: "space-between",
        marginLeft: 4,
        marginRight: 4,
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Typography variant={isSmallScreen ? "t7" : "h1"}>
        Les intellectuels
      </Typography>

      <Box sx={{ marginLeft: "auto", marginRight: 6 }}>
        <LiTextButton
          text={
            user?.name
              ? isSmallScreen
                ? getInitials(user?.name)
                : user?.name
              : "NA"
          }
          textVariant={isSmallScreen ? "s3" : "sm"}
          onClick={handleMenuClick}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={0}
        sx={{
          ".MuiPaper-root": { backgroundColor: theme.palette.secondary.main },
        }}
      >
        {/* <MenuItem onClick={() => handleMenuClose("profile")}>
          Edit Profile
        </MenuItem> */}
        <MenuItem onClick={() => handleMenuClose("signout")}>
          <Typography variant="s3" sx={{ fontStyle: "Italic" }}>
            Sign out
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function getInitials(name) {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
}
