import { TextField } from "@mui/material";
import axios from "axios";

// TODO implement debounce for the http call
export default function ComradeSearchField(props) {
  const { setComrades, sx = {} } = props;
  const handleChange = async (event) => {
    if (event.target?.value.length > 2) {
      const params = {
        text: event.target.value,
      };
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/search`,
        { params }
      );
      console.log(res.data);
      setComrades(res.data);
    }
  };
  return <TextField variant="standard" onChange={handleChange} sx={sx} />;
}
