import { Box, Typography, useTheme } from "@mui/material";
import ComradeSearchField from "../../shared/ComradeSearchField";
import { useState } from "react";
import ComradeShort from "../../shared/ComradeShort";

export default function SearchComrades(props) {
  const { navigate, setCurrentComrade } = props;

  const [comrades, setComrades] = useState([]);
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: theme.my.formWidth,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="s1">
        Type at least 3 characters to search comrades
      </Typography>
      <ComradeSearchField setComrades={setComrades} sx={{ mb: 3, mt: 2 }} />

      {comrades.map((c) => (
        <ComradeShort
          key={c._id}
          comrade={c}
          setCurrentComrade={setCurrentComrade}
          navigate={navigate}
        />
      ))}
    </Box>
  );
}
