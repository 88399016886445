import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LiTextButton from "./LiTextButton";

export default function Navigation() {
  const navigate = useNavigate();

  return (
    <Box
      position="fixed"
      bottom="20px"
      right="20px"
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      sx={{ width: "20px" }}
    >
      <LiTextButton text="M" onClick={() => navigate("/dashboard")} />
      <LiTextButton text="O" onClick={() => navigate("/search")} />
      <LiTextButton text="F" onClick={() => navigate("/following")} />
    </Box>
  );
}
