import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [backendStatus, setBackendStatus] = React.useState(null);

  const submit = async (values) => {
    const { email } = values;
    console.log(email);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/forgot`,
        {
          email,
        }
      );
      console.log(res);
      setBackendStatus(res?.data);
    } catch (error) {
      console.log(error);
      setBackendStatus(error?.response?.data);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">
          Forgot your password? That's okay, it happens. Fill out your email
          address below and you will receive an email with reset password
          instructions.{" "}
        </Typography>
        {backendStatus && (
          <Alert icon={false} severity="error">
            {backendStatus}
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(submit)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...register("email", {
                  required: "Email address is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Enter a valid email address",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
