import React, { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import axios from "axios";

const AuthContextProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   console.log("authenticated value has been updated:", authenticated);
  // }, [authenticated]);

  // Check if already authenticated on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/authenticated`,
          {
            withCredentials: true, //TODO don't really need it as the option is enabled globally (or maybe I need to disable the global option)
          }
        );
        console.log(
          `AuthContextProvider validate response code is ${res?.status}`
        );
        if (res?.status === 200) {
          console.log("updating auth context with the user");
          setUser(res.data);
          setAuthenticated(true);
          console.log("👍", res);
        }
      } catch (error) {
        console.log(error);
        console.log(
          `Authenticated response code is ${error?.response?.status}`
        );
        setAuthenticated(false);
        // console.error("Error fetching data:", error);
      }
    };

    // Call the async function
    fetchData();
  }, []); // The empty dependency array ensures this effect runs only once on component mount

  // Function to update the context value
  const updateAuthenticated = (newValue) => {
    console.log("updateAuthenticated");
    console.log(`new value is ${newValue}`);
    console.log(`old values i ${authenticated}`);
    setAuthenticated(newValue);
  };

  return (
    <AuthContext.Provider
      value={{ authenticated, updateAuthenticated, user, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
