import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ComradeShort from "../../shared/ComradeShort";

export default function List(props) {
  const { navigate, setCurrentComrade } = props;
  const [comrades, setComrades] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/following`
        );
        console.log(res);
        setComrades(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Typography variant="t5">Following</Typography>
      </Box>

      {comrades.map((c) => (
        <ComradeShort
          key={c._id}
          comrade={c}
          setCurrentComrade={setCurrentComrade}
          navigate={navigate}
        />
      ))}
    </Box>
  );
}
