import { Box, Typography } from "@mui/material";
import { BackToButton } from "./BackToButton";
import AnIList from "./AnIList";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import LiButton from "./LiButton";
import { ToastContext } from "./ToastContext";
import { AuthContext } from "../auth/AuthContext";

export default function ComradeFull(props) {
  const { comrade, navigate, backDestination } = props;

  const [completed, setCompleted] = useState([]);
  const [open, setOpen] = useState([]);

  const { setToastText } = useContext(ToastContext);

  const { setUser, user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      const params = {
        userId: comrade._id,
      };
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/ani`,
          { params }
        );
        console.log(res);

        setCompleted(res.data.completed);
        setOpen(res.data.open);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [comrade._id]);

  const handleFollow = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/follow`,
        {
          comradeId: comrade._id,
        }
      );
      // Update the user in the context
      setUser(res.data);
      setToastText("Followed");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };

  const handleUnfollow = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/unfollow`,
        {
          comradeId: comrade._id,
        }
      );
      console.log(res.data);
      // Update the user in the context
      setUser(res.data);
      setToastText("Unfollowed");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BackToButton
        navigate={navigate}
        text={`Back to ${backDestination}`}
        destination={backDestination}
      />
      <Typography sx={{ mt: 4 }}>{comrade?.name.toUpperCase()}</Typography>
      {user.following.includes(comrade._id) ? (
        <LiButton onClick={handleUnfollow} text="Unfollow" />
      ) : (
        <LiButton onClick={handleFollow} text="Follow" />
      )}

      <AnIList
        open={open}
        completed={completed}
        navigate={navigate}
        noActions={true}
      />
    </Box>
  );
}
