import { Box, Card, Typography, useTheme } from "@mui/material";

export default function ComradeShort(props) {
  const { comrade, setCurrentComrade, navigate } = props;

  const theme = useTheme();

  const handleCardClick = () => {
    console.log("card click");
    setCurrentComrade(comrade);
    navigate("display");
  };
  return (
    <Card
      onClick={handleCardClick}
      sx={{
        border: 0,
        marginTop: "8px",
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.2)",
        backgroundColor: "secondary.main",
        width: theme.my.formWidth,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography align="center" variant="s3" color="t.dark">
          {comrade.name}
        </Typography>
      </Box>
    </Card>
  );
}
