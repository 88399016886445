import { Box, Typography } from "@mui/material";
import AnI from "./AnI";
import LiTextButton from "./LiTextButton";
import { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";

export default function AnIList(props) {
  const {
    navigate,
    setCurrentAnI = null,
    open,
    completed,
    noActions = false,
  } = props;

  const { user } = useContext(AuthContext);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!noActions &&
          (open.length < user.maxOpenActivities ? (
            <LiTextButton
              text="A"
              onClick={() => {
                navigate("add");
              }}
              textVariant="t6"
              // textStyle={{ fontStyle: "Italic" }}
            />
          ) : (
            <Typography variant="s1" sx={{ color: "t.lightDark" }}>
              You cannot have more than {user.maxOpenActivities} open items
            </Typography>
          ))}

        {open.map((e) => (
          <AnI
            key={e._id}
            anI={e}
            setCurrentAnI={setCurrentAnI}
            navigate={navigate}
            noActions={noActions}
          />
        ))}
        {completed.map((e) => (
          <AnI
            key={e._id}
            anI={e}
            setCurrentAnI={setCurrentAnI}
            navigate={navigate}
            noActions={noActions}
          />
        ))}
      </Box>
    </>
  );
}
