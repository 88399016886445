import LiTextButton from "./LiTextButton";

export function BackToButton(props) {
  const { navigate, text, destination } = props;
  return (
    <LiTextButton
      onClick={() => {
        navigate(destination);
      }}
      text={`<- ${text}`}
      textVariant="t4"
    />
  );
}
