import * as React from "react";
import { DateTime } from "luxon";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ToastContext } from "../../shared/ToastContext";
import {
  MAX_CRITIQUE_CHARACTERS,
  MIN_CRITIQUE_CHARACTERS,
} from "../../constants";
import AnI from "../../shared/AnI";
import LiTextButton from "../../shared/LiTextButton";
import { BackToButton } from "../../shared/BackToButton";

export default function Critique(props) {
  const { navigate, anI } = props;
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const { setToastText } = useContext(ToastContext);

  const critiqueValue = watch("critique", "");

  const submit = async (values) => {
    setSubmitDisabled(true);

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/ani`, {
        id: anI._id,
        fieldsToUpdate: {
          critique: critiqueValue,
          status: "completed",
          completedAt: DateTime.local().toISO(),
        },
      });
      setToastText("Critique added");
      navigate("list");
    } catch (error) {
      console.log(error);
      setToastText("Something went wrong. Please try again ...");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BackToButton
        navigate={navigate}
        text="Back to List"
        destination="list"
      />

      {/* <Typography variant="s1" sx={{ color: "t.lightDark", marginTop: 3 }}>
        Fill out your critique (limited to {MAX_CRITIQUE_CHARACTERS} characters)
      </Typography> */}

      <Box sx={{ mt: 4 }}>
        <AnI anI={anI} shortFormat={true} />
      </Box>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(submit)}
        sx={{ mt: 2, width: theme.my.formWidth }}
      >
        <Controller
          name="critique"
          control={control}
          rules={{
            required: true,
            minLength: MIN_CRITIQUE_CHARACTERS,
            maxLength: MAX_CRITIQUE_CHARACTERS,
          }}
          render={({ field }) => (
            <TextField
              name="critique"
              required
              fullWidth
              multiline
              rows={6}
              id="critique"
              label="Critique"
              variant="standard"
              error={!!errors.critique}
              helperText={
                errors.critique &&
                `Critique length should be between ${MIN_CRITIQUE_CHARACTERS} and ${MAX_CRITIQUE_CHARACTERS} characters`
              }
              inputProps={{ maxLength: MAX_CRITIQUE_CHARACTERS }} // Set the maximum length
              {...field}
            />
          )}
        />

        <Typography variant="s1" color="t.lightDark">
          {critiqueValue.length}/{MAX_CRITIQUE_CHARACTERS}
        </Typography>
        <Box sx={{ mt: 4 }}>
          <LiTextButton
            text="Add Critique"
            disabled={submitDisabled}
            onClick={submit}
          />
        </Box>
      </Box>
    </Box>
  );
}
