import { Box } from "@mui/material";
import Bar from "../../shared/Bar";
import { useState } from "react";
import Scene from "../../shared/Scene";
import Navigation from "../../shared/Navigation";
import List from "./List";
import ComradeFull from "../../shared/ComradeFull";

export default function Following() {
  const [page, setPage] = useState("list");
  const [currentComrade, setCurrentComrade] = useState(null);

  return (
    <Scene>
      <Bar />
      <Box
        component="span"
        sx={{
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
          marginBottom: 4,
        }}
      >
        {page === "list" && (
          <List navigate={setPage} setCurrentComrade={setCurrentComrade} />
        )}
        {page === "display" && (
          <ComradeFull
            navigate={setPage}
            comrade={currentComrade}
            backDestination="list"
          />
        )}
      </Box>
      <Navigation />
    </Scene>
  );
}
